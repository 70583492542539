/* Reusable, object-oriented CSS base components for all Zulip pages
   (both web app and portico). */

.new-style {
  & label.checkbox {
    padding: 0;
    display: inline-block;
    position: relative;
    vertical-align: top;
    min-height: 20px;

    & input[type="checkbox"] {
      position: absolute;
      clip: rect(0, 0, 0, 0);

      ~ span {
        display: inline-block;
        vertical-align: middle;
        position: relative;
        top: -2px;

        padding: 2px;
        margin: 0 5px 0 0;
        height: 10px;
        width: 10px;

        font-weight: 300;
        line-height: 0.8;
        font-size: 1.3rem;
        text-align: center;
        border: 1px solid hsl(0deg 0% 0% / 60%);

        border-radius: 4px;
        filter: brightness(0.8);

        cursor: pointer;
      }

      &:checked ~ span {
        background-image: url("../images/checkbox.svg");
        background-size: 85%;
        background-position: 50% 50%;
        background-repeat: no-repeat;
      }

      &:disabled ~ span {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }

  & a.no-style {
    color: inherit;
  }
}

a.no-underline,
a.no-underline:hover {
  text-decoration: none;
}

.half-opacity {
  opacity: 0.5;
}

.italic {
  font-style: italic;
}

.simplebar-hover {
  overflow: hidden;
}

.simplebar-wrapper {
  overflow: hidden;
}

#left_sidebar_scroll_container > .simplebar-wrapper {
  height: 33rem;
}

.simplebar-track {
  .simplebar-scrollbar::before {
    background-color: hsl(0deg 0% 0%);
    box-shadow: 0 0 0 1px hsl(0deg 0% 100% / 33%);
  }

  &.simplebar-vertical {
    transition: width 0.2s ease 1s;

    &.simplebar-hover {
      width: 15px;
      height: 50%;
      transition: width 0.2s ease;
    }
  }

  &.simplebar-horizontal {
    transition: height 0.2s ease 1s;

    &.simplebar-hover {
      height: 15px;
      transition: height 0.2s ease;
    }
  }
}

i.zulip-icon.zulip-icon-bot {
  color: var(--color-icon-bot);
  vertical-align: top;
  padding: 0 2px;
}

.tippy-content {
  /* Set the default font size for tooltips. Popovers override this with
       a rule looking at the data-theme.
     */
  font-size: 12px;
}

/* Hide the somewhat buggy browser show password feature in IE, Edge,
   since it duplicates our own "show password" widget. */
input::-ms-reveal {
  display: none;
}

.password-div {
  position: relative;

  .password_visibility_toggle {
    position: absolute;
    right: 10px;
    top: 42px;
    opacity: 0.6;

    &:hover {
      opacity: 1;
    }
  }
}

select.bootstrap-focus-style {
  &:focus {
    outline: 1px dotted hsl(0deg 0% 20%);
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
    transition: none;
  }
}
